<template>
  <table class="rm-table-values">
    <tbody>
      <tr v-for="(chunk, idx) in this.chunks" :key="idx">
        <td
            v-for="(val, idy) in chunk"
            v-bind:key="idy"
            class="rm-table-td-value"
            @click="setValue(val)">
          {{val}}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableValues",
  props: {
    game: {
      type: String,
      required: true,
    },
    gameStoreObj: {
      type: Object,
      required: true,
    },
    validValues: {
      type: Array,
      default: () => [],
    },
    numValuesPerRow: {
      type: Number,
      default: 10,
    }
  },
  computed: {
    numRows() {
      return Math.ceil(this.validValues.length / this.numValuesPerRow)
    },
    chunks() {
      let chunkArr = []
      let i = 0;
      let n = this.validValues.length;

      while(i < n){
        chunkArr.push(this.validValues.slice(i, i+=this.numValuesPerRow))
      }

      return chunkArr;
    }
  },
  methods: {
    setValue(num) {
      this.gameStoreObj.commit(this.game + "Store/setValue", num);
    },
  }
}
</script>

<style scoped>

</style>