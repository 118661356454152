<template>
  <div>
    <p v-html="$t('instructions.caesarCypher.p1')"></p>
    <h5>{{ $t("instructions.caesarCypher.cypherProcedure") }}</h5>
    <p v-html="$t('instructions.caesarCypher.p2')"></p>
    <p v-html="$t('instructions.caesarCypher.p3')"></p>
    <h5>{{ $t("instructions.caesarCypher.encryptionExample") }}</h5>
    <p v-html="$t('instructions.caesarCypher.p4')"></p>
    <p v-html="$t('instructions.caesarCypher.p5')"></p>
    <p v-html="$t('instructions.caesarCypher.p6')"></p>
    <p v-html="$t('instructions.caesarCypher.p7')"></p>
    <h5>{{ $t("instructions.caesarCypher.decipherProcedure") }}</h5>
    <p v-html="$t('instructions.caesarCypher.p8')"></p>
    <h5>{{ $t("instructions.caesarCypher.cypherSecurity") }}</h5>
    <p v-html="$t('instructions.caesarCypher.p9')"></p>
    <p v-html="$t('instructions.caesarCypher.p10')"></p>
    <p v-html="$t('instructions.caesarCypher.p11')"></p>
  </div>
</template>

<script>
export default {
  name: "CaesarCypherInstructions",
};
</script>

<style scoped></style>
