<template>
  <td
      :class="getCellClass(rowIndex, colIndex)"
      v-on:click.left.exact="setSelected(rowIndex, colIndex)"
      v-on:contextmenu.exact.prevent="markMine(rowIndex, colIndex)"
      v-html="getCellHtml(rowIndex, colIndex)"
  ></td>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "MinesweeperCell",
  props: ["rowIndex", "colIndex", "cell"],
  computed: {
    ...mapState("minesweeperStore", {
      grid: (state) => state.grid,
      killed: (state) => state.killed,
      selectedCell: (state) => state.selectedCell,
      completed: (state) => state.completed,
      solutionAchieved: (state) => state.solutionAchieved,
    }),
  },
  methods: {
    setSelected(x, y) {
      this.$store.commit("minesweeperStore/setSelected", {x, y});
    },
    markMine(x, y) {
      this.$store.commit("minesweeperStore/markMine", {x, y});
    },
    getCellClass(x, y) {
      let baseClass = "ms";
      if ("locked" in this.grid[x][y] && this.grid[x][y].locked) {
        return baseClass + " rm-locked cell-locked";
      }

      if (this.solutionAchieved) {
        return baseClass + " rm-solved";
      } else if (this.killed) {
        if (this.grid[x][y].t === "M") {
          if (
              this.selectedCell &&
              this.selectedCell.x === x &&
              this.selectedCell.y === y
          ) {
            return baseClass + " rm-wrong";
          }
        } else if (this.grid[x][y].t === "E" && this.grid[x][y].u === "R") {
          return baseClass + " rm-revealed";
        }
      } else {
        if (this.grid[x][y].t === "E" && this.grid[x][y].u === "R") {
          return baseClass + " rm-revealed";
        }
      }
      return baseClass;
    },
    getCellHtml(x, y) {
      if (this.killed) {
        if (this.grid[x][y].t === "M") {
          return "<i class='fa fa-bomb'></i>";
        } else if (
            this.grid[x][y].t === "E" &&
            this.grid[x][y].u === "R" &&
            this.grid[x][y].n > 0
        ) {
          return this.grid[x][y].n;
        }
      } else {
        if (
            this.grid[x][y].t === "E" &&
            this.grid[x][y].u === "R" &&
            this.grid[x][y].n > 0
        ) {
          return this.grid[x][y].n;
        } else if (this.grid[x][y].u === "M") {
          return "<i class='fa fa-flag'></i>";
        }
      }
      return "";
    },
  },
};
</script>

<style>
td.ms {
  text-align: center;
  height: 2em;
  min-height: 2em;
  border: 1px solid;
  width: 1em;
  max-width: 1em;
  background-color: #ebebeb;
  font-size: large;
}

.cell-locked {
  cursor: default;
}
</style>
