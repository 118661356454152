<template>
  <div>
    <p v-html="$t('instructions.minesweeper.p1')"></p>
    <h5>{{ $t("games.instructions.common.howToPlay") }}</h5>
    <p v-html="$t('instructions.minesweeper.p2')"></p>
    <p v-html="$t('instructions.minesweeper.leftClick')"></p>
    <p v-html="$t('instructions.minesweeper.l1')"></p>
    <p v-html="$t('instructions.minesweeper.rightClick')"></p>
    <p v-html="$t('instructions.minesweeper.r1')"></p>
    <h5>{{ $t("instructions.minesweeper.goal") }}</h5>
    <p v-html="$t('instructions.minesweeper.p3')"></p>
  </div>
</template>

<script>
export default {
  name: "MinesweeperInstructions",
};
</script>

<style scoped></style>
