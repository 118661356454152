<template>
  <td :id="tdId" class="rm-locked">
    {{ hintNumber }}
    <b-tooltip :target="tdId" noninteractive>
      <span v-if="hasEastHint && hasSouthHint">
        {{ $t("games.dispatch.horizontally") }}: {{ eastHint }}<br/>{{ $t("games.dispatch.vertically") }}: {{
          southHint
        }}</span>
      <span v-else-if="hasEastHint"> {{ $t("games.dispatch.horizontally") }}: {{ eastHint }} </span>
      <span v-else-if="hasSouthHint"> {{ $t("games.dispatch.vertically") }}: {{ southHint }} </span>
    </b-tooltip>
  </td>
</template>

<script>

export default {
  name: "CrosswordHintCell",
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    gameStore: {
      type: String,
      required: true,
    }
  },
  computed: {
    grid() {
      return this.$store.state[this.gameStore].grid;
    },
    hintNumber() {
      return this.grid[this.rowIndex][this.colIndex].value;
    },
    tdId() {
      return `hint_${this.rowIndex}_${this.colIndex}`;
    },
    hasEastHint() {
      return "he" in this.grid[this.rowIndex][this.colIndex];
    },
    hasSouthHint() {
      return "hs" in this.grid[this.rowIndex][this.colIndex];
    },
    eastHint() {
      return this.grid[this.rowIndex][this.colIndex].he;
    },
    southHint() {
      return this.grid[this.rowIndex][this.colIndex].hs;
    },
  },
};
</script>

<style scoped>
td {
  cursor: default !important;
  font-size: large;
}
</style>
