<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="allFilledUp"
        v-bind:has-wrong-values="hasWrongValues"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <hr/>
    <h5>{{ $t("games.games.meme.findOutSequence") }}</h5>

    <table class="meme" v-for="(row, idx) in grid"
           :key="idx">
      <tbody>
      <tr>
        <game-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
            v-bind:grid="grid"
            v-bind:solution-achieved="solutionAchieved"
            @cellClicked="cellClick"
        ></game-cell>
      </tr>
      </tbody>
    </table>

    <h5>{{ $t("games.games.meme.possibleValues") }}</h5>

    <table class="meme">
      <tbody>
      <tr>
        <td class="val" v-for="(symbol, sid) in this.gridSymbols" v-bind:key="sid" @click="setNumber(symbol)">
          <button type="button" :class="getButtonClass(symbol)"></button>
        </td>
        <td class="val" @click="unsetNumber()"></td>
      </tr>
      </tbody>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import GameCell from "@/components/games/GameCell";
import GameMessages from "@/components/games/GameMessages.vue";
import {mapState} from "vuex";
import {getButtonClass} from "@/_helpers/games/meme-helper";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "MemeGrid",
  props: {
    game: {
      type: String,
      default: "meme"
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GameActions,
    GameCell,
    GameMessages
  },
  computed: {
    ...mapState("memeStore", {
      solutionAchieved: (state) => state.solutionAchieved,
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
      grid: (state) => state.grid,
      gridSymbols: (state) => state.gridSymbols,
    }),
  },
  methods: {
    cellClick(obj) {
      if (!this.grid[obj.x][obj.y].marked) {
        this.$store.commit(this.game + "Store/setSelected", obj);
      }
    },
    getButtonClass(num) {
      return getButtonClass(num);
    },
    setNumber(num) {
      this.$store.commit(this.game + "Store/setNumber", num);
    },
    unsetNumber() {
      this.$store.commit(this.game + "Store/setNumber", 0);
    },
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
}
</script>

<style scoped>
table.meme {
  margin-top: 15px;
  border-collapse: collapse;
  border: 2px solid;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  margin-bottom: 1em;
}

td.val {
  text-align: center;
  height: 2em;
  min-height: 2em;
  border: 1px solid;
  width: 1em;
  max-width: 1em;
  font-size: large;
}

.btn-red {
  background-color: red;
  border-color: red;
}

.btn-green {
  background-color: green;
  border-color: green;
}

.btn-purple {
  background-color: purple;
  border-color: purple;
}

.btn-yellow {
  background-color: yellow;
  border-color: yellow;
}

.btn-blue {
  background-color: blue;
  border-color: blue;
}

.btn-black {
  background-color: black;
  border-color: black;
}

.btn-white {
  background-color: white;
  border-color: black;
}

.btn-orange {
  background-color: orange;
  border-color: orange;
}

.btn-pink {
  background-color: pink;
  border-color: pink;
}

.btn-cyan {
  background-color: cyan;
  border-color: cyan;
}

.btn-gold {
  background-color: gold;
  border-color: gold;
}

.btn-brown {
  background-color: brown;
  border-color: brown;
}

</style>