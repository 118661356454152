import { render, staticRenderFns } from "./WordSearchInstructions.vue?vue&type=template&id=388aa725&scoped=true"
import script from "./WordSearchInstructions.vue?vue&type=script&lang=js"
export * from "./WordSearchInstructions.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "388aa725",
  null
  
)

export default component.exports