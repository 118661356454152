<template>
  <td :class="cellClass" @click="setNewPosition(rowIndex, colIndex)"></td>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MazeCell",
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    cell: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState("simpleMazeStore", {
      selectedCell: (state) => state.selectedCell,
      solutionAchieved: (state) => state.solutionAchieved,
    }),
    cellClass() {
      let baseClass = "";

      let walls = this.cell;
      if (walls.charAt(0) === "1") baseClass = baseClass + " b-top";
      if (walls.charAt(1) === "1") baseClass = baseClass + " b-right";
      if (walls.charAt(2) === "1") baseClass = baseClass + " b-bottom";
      if (walls.charAt(3) === "1") baseClass = baseClass + " b-left";

      if (this.solutionAchieved) {
        baseClass = baseClass + " rm-solved";
      } else if (
        this.rowIndex === this.selectedCell[0] &&
        this.colIndex === this.selectedCell[1]
      ) {
        baseClass = baseClass + " rm-selected";
      }

      return baseClass;
    },
  },
  methods: {
    setNewPosition(x, y) {
      this.$store.commit("simpleMazeStore/moveToPosition", { x, y });
    },
  },
};
</script>

<style scoped>
td {
  border: none;
  width: 0.5em;
  height: 1em;
}

td.b-top {
  border-top: 2px solid;
}

td.b-right {
  border-right: 2px solid;
}

td.b-bottom {
  border-bottom: 2px solid;
}

td.b-left {
  border-left: 2px solid;
}
</style>
