<template>
  <div>
    <p v-html="$t('instructions.meme.p1')"></p>
    <p v-html="$t('instructions.meme.p2')"></p>
    <h5>{{ $t("games.instructions.common.howToPlay") }}</h5>
    <p v-html="$t('instructions.meme.p3')"></p>
    <p class="d-flex justify-content-center">
      <img
          :src="imageLinks['img001']"
          class="panel-image"
          width="80%"
      />
    </p>
    <p v-html="$t('instructions.meme.p4')"></p>
    <h5>{{ $t("instructions.meme.goal") }}</h5>
    <p v-html="$t('instructions.meme.g1')"></p>
  </div>
</template>

<script>
export default {
  name: "MemeInstructions",
  data() {
    return {
      imageLinks: {
        img001: require("@/assets/images/games/meme/instructions/img-001.webp"),
      },
    };
  },
};
</script>
