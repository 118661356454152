<template>
  <td v-if="cellHtml === ''" v-on:click="cellClick" :class="cellClass">{{ cellValue }}</td>
  <td v-else v-on:click="cellClick" :class="cellClass" v-html="cellHtml"></td>
</template>

<script>
import {getGridCellClass} from "@/config/stores/common";

export default {
  name: "GameCell",
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    grid: {
      type: Array,
      default: () => [],
    },
    solutionAchieved: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cellValue() {
      return this.grid[this.rowIndex][this.colIndex].value;
    },
    cellHtml() {
      if ("html" in this.grid[this.rowIndex][this.colIndex] && this.grid[this.rowIndex][this.colIndex].html !== null) {
        return this.grid[this.rowIndex][this.colIndex].html;
      }
      return "";
    },
    cellClass() {
      let baseClass = "ns";
      return getGridCellClass(
          this.grid,
          this.rowIndex,
          this.colIndex,
          this.solutionAchieved,
          baseClass
      );
    },
  },
  methods: {
    cellClick() {
      if (
          "locked" in this.grid[this.rowIndex][this.colIndex] &&
          this.grid[this.rowIndex][this.colIndex].locked
      ) {
        return;
      }

      this.$emit("cellClicked", {x: this.rowIndex, y: this.colIndex});
    },
  },
};
</script>

<style scoped>
td.ns {
  text-align: center;
  height: 2em;
  min-height: 2em;
  border: 1px solid;
  width: 1em;
  max-width: 1em;
  font-size: large;
}
</style>
