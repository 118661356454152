<template>
  <div>
    <p v-html="$t('instructions.sudoku.p1')"></p>
    <h5>{{ $t("games.instructions.common.howToPlay") }}</h5>
    <p v-html="$t('instructions.sudoku.p2')"></p>
  </div>
</template>

<script>
export default {
  name: "SudokuInstructions",
};
</script>

<style scoped></style>
