<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="allFilledUp"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <hr/>
    <table class="word-search">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <game-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
            v-bind:grid="grid"
            v-bind:solution-achieved="solutionAchieved"
            @cellClicked="cellClick"
        ></game-cell>
      </tr>
      </tbody>
    </table>
    <h5>{{ this.$t("games.games.wordSearch.hiddenSequences") }}</h5>
    <table class="words">
      <tbody>
      <tr v-for="(row, idx) in words" :key="idx">
        <td
            class="words"
            v-for="(cell, idy) in row"
            :key="idy"
            :class="setNumberClass(idx, idy)"
            @click="selectWord(idx, idy)"
        >
          {{ words[idx][idy].w }}
        </td>
      </tr>
      </tbody>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import GameCell from "@/components/games/GameCell";
import GameMessages from "@/components/games/GameMessages.vue";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "WordSearchGrid",
  props: {
    game: {
      type: String,
      default: "wordSearch"
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GameActions,
    GameCell,
    GameMessages,
  },
  computed: {
    ...mapState("wordSearchStore", {
      solutionAchieved: (state) => state.solutionAchieved,
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      grid: (state) => state.grid,
      words: (state) => state.words,
      selectedWord: (state) => state.selectedWord,
    }),
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    handleKeyPress(e) {
      e.preventDefault();
    },
    selectWord(x, y) {
      this.$store.commit(this.game + "Store/selectWord", {x, y});
    },
    setNumberClass(x, y) {
      let word = this.words[x][y];

      if ("locked" in word && word.locked) {
        return "rm-locked cursor-default";
      }

      let baseClass = "";
      if (word.f) {
        return baseClass + " rm-solved cursor-default";
      }

      if (
          this.selectedWord !== null &&
          this.selectedWord.x === x &&
          this.selectedWord.y === y
      ) {
        return baseClass + " rm-selected";
      }
    },
    cellClick(obj) {
      this.$store.commit(this.game + "Store/setSelected", obj);
    },
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss" scoped>
table.word-search {
  margin-top: 30px;
  border-collapse: collapse;
  border: none;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  margin-bottom: 1em;

}

table.words {
  margin-top: 30px;
  border-collapse: collapse;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: large;
}

td.words {
  border: 1px solid;
  text-align: center;
  width: 40px;
  height: 40px;
}

.cursor-default {
  cursor: default;
}
</style>
