<template>
  <div>
    <p v-html="$t('instructions.simpleMath.p1')"></p>
    <p v-html="$t('instructions.simpleMath.p2')"></p>
    <h5>{{ $t("games.instructions.common.howToPlay") }}</h5>
    <p v-html="$t('instructions.simpleMath.p3')"></p>
    <p v-html="$t('instructions.simpleMath.p4')"></p>
  </div>
</template>

<script>
export default {
  name: "SimpleMathInstructions",
};
</script>
