<template>
  <td class="rm-locked"></td>
</template>

<script>
export default {
  name: "CrosswordEmptyCell",
};
</script>

<style scoped>
td {
  cursor: default;
}
</style>
