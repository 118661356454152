<template>
  <game-cell
      v-if="cell.type !== 'EMPTY'"
      v-bind:rowIndex="rowIndex"
      v-bind:colIndex="colIndex"
      v-bind:solution-achieved="solutionAchieved"
      v-bind:grid="grid"
      @cellClicked="cellClick"
  ></game-cell>
  <caesar-cypher-empty-cell v-else></caesar-cypher-empty-cell>
</template>

<script>
import GameCell from "@/components/games/GameCell";
import CaesarCypherEmptyCell from "./CaesarCypherEmptyCell";

export default {
  name: "CaesarCypherCell",
  props: ["rowIndex", "colIndex", "cell", "grid", "solutionAchieved"],
  components: {
    CaesarCypherEmptyCell,
    GameCell,
  },
  methods: {
    cellClick(obj) {
      this.$emit("cellClick", obj);
    },
  },
};
</script>
