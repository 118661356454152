<template>
  <div>
    <GameMessages
        v-bind:all-filled-up="allFilledUp"
        v-bind:game="game"
        v-bind:period="period"
    >
    </GameMessages>
    <hr/>
    <table class="maze">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <maze-cell
            v-for="(cell, idy) in row"
            :key="idy"
            v-bind:cell="cell"
            v-bind:row-index="idx"
            v-bind:col-index="idy"
        ></maze-cell>
      </tr>
      </tbody>
    </table>
    <table class="directions">
      <tr>
        <td></td>
        <td class="bordered" @click="handleDirectionPress(0)">
          <i class="fa fa-arrow-up"></i>
        </td>
        <td></td>
      </tr>
      <tr>
        <td class="bordered" @click="handleDirectionPress(2)">
          <i class="fa fa-arrow-left"></i>
        </td>
        <td class="bordered" @click="handleDirectionPress(1)">
          <i class="fa fa-arrow-down"></i>
        </td>
        <td class="bordered" @click="handleDirectionPress(3)">
          <i class="fa fa-arrow-right"></i>
        </td>
      </tr>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import MazeCell from "@/components/games/simple_maze/MazeCell";
import GameMessages from "@/components/games/GameMessages.vue";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "SimpleMazeGrid",
  props: {
    game: {
      type: String,
      default: "simpleMaze",
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    GameActions,
    MazeCell,
    GameMessages,
  },
  computed: {
    ...mapState("simpleMazeStore", {
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      grid: (state) => state.grid,
      selectedCell: (state) => state.selectedCell,
    }),
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    handleKeyPress(e) {
      e.preventDefault();
      let upperCaseKey = e.key.toUpperCase();
      if (upperCaseKey === "ARROWUP") {
        this.$store.commit("simpleMazeStore/moveInDirection", 0);
      } else if (upperCaseKey === "ARROWDOWN") {
        this.$store.commit("simpleMazeStore/moveInDirection", 1);
      } else if (upperCaseKey === "ARROWLEFT") {
        this.$store.commit("simpleMazeStore/moveInDirection", 2);
      } else if (upperCaseKey === "ARROWRIGHT") {
        this.$store.commit("simpleMazeStore/moveInDirection", 3);
      }
    },
    handleDirectionPress(direction) {
      this.$store.commit(this.game + "Store/moveInDirection", direction);
    },
  },
  created() {
    this.$store.commit(this.game + "Store/initGrid", {
      period: this.period,
      gridId: this.gameId,
    });
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss" scoped>
table.maze {
  margin-top: 30px;
  border-collapse: collapse;
  border: none;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  margin-bottom: 1em;
}

table.directions {
  border-collapse: collapse;
  border: none;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: x-large;
  margin: 30px auto 1em;
}

table.directions td {
  width: 2em;
  height: 1.5em;
  min-height: 1.5em;
}

td.bordered {
  border: 1px solid;
}
</style>
