<template>
  <td @click="setSelected(rowIndex, colIndex)" :class="cellClass">
    {{ cellValue }}
  </td>
</template>

<script>
import { getGridCellClass } from "@/config/stores/common";

export default {
  name: "CrosswordLetterCell",
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    gameStore: {
      type: String,
      required: true,
    }
  },
  computed: {
    grid() {
      return this.$store.state[this.gameStore].grid;
    },
    solutionAchieved() {
      return this.$store.state[this.gameStore].solutionAchieved;
    },
    cellValue() {
      return this.grid[this.rowIndex][this.colIndex].value;
    },
    cellClass() {
      return getGridCellClass(
        this.grid,
        this.rowIndex,
        this.colIndex,
        this.solutionAchieved,
        ""
      );
    },
  },
  methods: {
    setSelected(x, y) {
      if (!this.grid[x][y].locked) {
        this.$store.commit(this.gameStore + "/setSelected", { x, y });
      }
    },
  },
};
</script>

<style scoped></style>
