<template>
  <td></td>
</template>

<script>
export default {
  name: "CaesarCypherEmptyCell",
};
</script>

<style scoped>
td {
  text-align: center;
  height: 1em;
  min-height: 1em;
  border: none !important;
  width: 10%;
  cursor: default;
}
</style>
