<template>
  <div>
    <p v-html="$t('instructions.mathCrossword.p1')"></p>
    <h5>{{ $t("games.instructions.common.howToPlay") }}</h5>
    <p v-html="$t('instructions.mathCrossword.p2')"></p>
    <p v-html="$t('instructions.mathCrossword.p3')"></p>
    <p v-html="$t('instructions.mathCrossword.p4')"></p>
    <p v-html="$t('instructions.mathCrossword.p5')"></p>
  </div>
</template>

<script>
export default {
  name: "MathCrosswordInstructions",
};
</script>
