<template>
  <crossword-letter-cell
    v-if="cell.type === 'L'"
    v-bind:row-index="rowIndex"
    v-bind:col-index="colIndex"
    v-bind:game-store="gameStore"
  ></crossword-letter-cell>
  <crossword-hint-cell
    v-else-if="cell.type === 'H'"
    v-bind:row-index="rowIndex"
    v-bind:col-index="colIndex"
    v-bind:game-store="gameStore"
  ></crossword-hint-cell>
  <crossword-empty-cell
    v-else-if="cell.type === 'E'"
  ></crossword-empty-cell>
</template>

<script>

import CrosswordHintCell from "@/components/games/crossword/CrosswordHintCell";
import CrosswordLetterCell from "@/components/games/crossword/CrosswordLetterCell";
import CrosswordEmptyCell from "@/components/games/crossword/CrosswordEmptyCell";

export default {
  name: "CrosswordCell",
  components: {
    CrosswordHintCell,
    CrosswordLetterCell,
    CrosswordEmptyCell,
  },
  props: {
    rowIndex: {
      type: Number,
      required: true,
    },
    colIndex: {
      type: Number,
      required: true,
    },
    cell: {
      type: Object,
      required: true,
    },
    gameStore: {
      type: String,
      required: true,
    }
  },
};
</script>

<style scoped>
td {
  border: 1px solid;
  width: 1em;
  max-width: 1em;
  height: 2em;
  max-height: 2em;
}
</style>
